import { Link } from "gatsby"
import React from 'react'
import GGFXImage from "../../modules/GGFXImage"
import "./OffplanSearchSlider.scss"
import CustomSlider from "../CustomSlider/CustomSlider"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { Site_Vars } from "../../common/site/config"
import dateFormat from "dateformat"
import { numberFormat } from "../Common/utils"


const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const OffplanSearchSlider = ({moduleData,offplanData, isDynamic}) => {

  const {isMobile} =useDeviceMedia()
  const text ="Hi, I've come across the haus & haus Group and would like further information from you."
  return (
    <div className="offplan-container-slider">
    <h2 className="title">{moduleData?.title}</h2>
    <p className="description">
    <ContentModule Content={moduleData?.description?.data?.description} />
    </p>
    <CustomSlider 
      noDots
      showArrows
      iconchange
      slidecount={isMobile ? 1.2 : 4}
      className="res-offplan-slider slider-blocks"
    >
      
      {offplanData?.length>0 & !isDynamic ? (
      offplanData?.map((item, index) => {
        const whatsappNum = item?.attributes?.whatsapp ? item?.attributes?.whatsapp:
        item?.whatsapp ? item?.whatsapp : "97143025800"

        const phoneNumber = item?.phone_number ? item?.phone_number : item?.attributes?.phone_number ?
          item?.attributes?.phone_number : "97143025800"

        const slug = `${item?.attributes?.slug ? item?.attributes?.slug : 
        item?.slug}/${item?.attributes?.crm_id ? item?.attributes?.crm_id : 
          item?.crm_id
        }`

        const imageItem = item?.attributes?.images?.length > 0 ? item?.attributes?.images :
        item?.images?.length>0 ? item?.images : ""

        const priceValue = item?.attributes?.price>0 ? item?.attributes?.price :
        item?.price>0 ? item?.price:""

        return (
          <div
            className="offplan-res-slider-item"
          >
            <div className="offplan-slider-item">
              <div className="image-section">
                <Link
                  to={`/off-plan/properties/for-sale/in-dubai/${slug}/`}
                >
                  {imageItem && (
                    <GGFXImage
                      ImageSrc={imageItem[0]}
                      altText={item?.attributes?.banner_title || item?.banner_title}
                      imagetransforms={item?.attributes?.ggfx_results || item?.ggfx_results}
                      renderer="srcSet"
                      imagename="new-developments.images.tileimg"
                      strapiID={item?.id || item?.strapi_id}
                    />
                  )}
                </Link>
              </div>
              <div className="content-btn-section">
                <div className="content-section">
                  <Link
                    to={`/off-plan/properties/for-sale/in-dubai/${slug}/`}
                  >
                    <h3 className="developer">
                      {item?.attributes?.banner_title || item?.banner_title}
                    </h3>
                    <p className="location">
                      <span>
                        <i className="icon property-location-icon" />
                      </span>
                      <span className="location-name">{item?.attributes?.area || item?.area}</span>
                    </p>
                  </Link>
                  <div className="infos-sec">
                  {(item?.attributes?.expected_completion_date || item?.expected_completion_date)&&
                    <p>
                      Delivery Date:{" "}
                      <span>
                      {dateFormat(
                      item?.attributes?.expected_completion_date || item?.expected_completion_date,
                      "mmm, yyyy"
                    )}
                      </span>
                    </p>
                  }
                  {priceValue&&
                    <p>
                      Price from:{" "}
                      <span>
                      {Site_Vars.default_currency}{" "}
                        {numberFormat(priceValue)}
                      </span>
                    </p>
                    }
                    {(item?.attributes?.developer || item?.developer) &&
                    <p>
                      Developer: <span>{item?.attributes?.developer || item?.developer}</span>
                    </p>
                    }
                  </div>
                </div>
                <div className="horizontal-line-border" />
                <div className="offplan_ctas">
                  <Link
                    to={`tel:${phoneNumber?.replace(/ /g, "")}`}
                    className="contact button"
                  >
                    <i className="icon icon-contact-call" />
                    <span>Call</span>
                  </Link>

                  <Link
                    to={`https://wa.me/${whatsappNum?.replace(
                      / /g,
                      ""
                    )}?&text=${encodeURIComponent(text)}`}
                    className="contact button"
                    target="_blank"
                  >
                    <i className="icon icon-contact-whatsapp" />
                    <span>Whatsapp</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      })
    )
    :
    
    offplanData?.length>0 && (
      offplanData?.map((item, index) => {
        const whatsappNum = item?.node?.whatsapp?.replace(" ","") ? item?.node?.whatsapp:
        item?.whatsapp ? item?.whatsapp : "97143025800"

        const phoneNumber = item?.phone_number ? item?.phone_number : item?.node?.phone_number ?
          item?.node?.phone_number : "97143025800"

        const slug = `${item?.node?.slug ? item?.node?.slug : 
        item?.slug}/${item?.node?.crm_id ? item?.node?.crm_id : 
          item?.crm_id
        }`

        const imageItem = item?.node?.images?.length > 0 ? item?.node?.images :
        item?.images?.length>0 ? item?.images : ""

        const priceValue = item?.node?.price>0 ? item?.node?.price :
        item?.price>0 ? item?.price:""

        return (
          <div
            className="offplan-res-slider-item"
          >
            <div className="offplan-slider-item">
              <div className="image-section">
                <Link
                  to={`/off-plan/properties/for-sale/in-dubai/${slug}/`}
                >
                  {imageItem && (
                    <GGFXImage
                      ImageSrc={imageItem[0]}
                      altText={item?.node?.banner_title}
                      imagetransforms={item?.node?.ggfx_results}
                      renderer="srcSet"
                      imagename="new-developments.images.tileimg"
                      strapiID={item?.node?.strapi_id}
                    />
                  )}
                </Link>
              </div>
              <div className="content-btn-section">
                <div className="content-section">
                  <Link
                    to={`/off-plan/properties/for-sale/in-dubai/${slug}/`}
                  >
                    <h3 className="developer">
                      {item?.node?.banner_title}
                    </h3>
                    <p className="location">
                      <span>
                        <i className="icon property-location-icon" />
                      </span>
                      <span className="location-name">{item?.node?.area}</span>
                    </p>
                  </Link>
                  <div className="infos-sec">
                  {(item?.node?.expected_completion_date || item?.expected_completion_date)&&
                    <p>
                      Delivery Date:{" "}
                      <span>
                      {dateFormat(
                       item?.node?.expected_completion_date || item?.expected_completion_date, 
                      "mmm, yyyy"
                    )}
                      </span>
                    </p>
                  }
                  {priceValue&&
                    <p>
                      Price from:{" "}
                      <span>
                        {Site_Vars.default_currency}{" "}
                        {numberFormat(priceValue)}
                      </span>
                    </p>
                    }
                    {(item?.node?.developer || item?.developer) &&
                    <p>
                      Developer: <span>{item?.node?.developer || item?.developer}</span>
                    </p>
                    }
                  </div>
                </div>
                <div className="horizontal-line-border" />
                <div className="offplan_ctas">
                  <Link
                    to={`tel:${phoneNumber?.replace(/ /g, "")}`}
                    className="contact button"
                  >
                    <i className="icon icon-contact-call" />
                    <span>Call</span>
                  </Link>

                  <Link
                    to={`https://wa.me/${whatsappNum?.replace(
                      / /g,
                      ""
                    )}?&text=${encodeURIComponent(text)}`}
                    className="contact button"
                    target="_blank"
                  >
                    <i className="icon icon-contact-whatsapp" />
                    <span>Whatsapp</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      })
    )
  }
    
    
    </CustomSlider>
  </div>
  )
}

export default OffplanSearchSlider